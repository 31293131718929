import rootConfig from './config/index.json';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import FAQ from '@funimation/comp-content-faq';
import UserInfo from '@funimation/comp-user-info';
import Footer from '@funimation/comp-global-footer';
import IdentityMigration from '@funimation/identity-migration';
import RemoteConfig from '@funimation/remote-config';
import { sync } from 'vuex-router-sync';
import { mapConfig, getFirebaseConfig } from '../src/config/helpers';

Vue.config.productionTip = false;

declare global {
  interface Window {
    app: unknown;
    initVueApp: () => Promise<void>;
    locale: string;
    region: string;
    defaultShow: string;
    datadogRumAddError: (error: Error, context?: any) => void;
  }
}

const env = ({
  'www.funimation.com': 'prd',
  'www-v2.funimation.com': 'stg',
  'qa-www.funimationtest.com': 'qa',
  'uat-www.funimationtest.com': 'stg',
} as Record<string, string>)[window.location.host as string] || 'sbx';

const firebaseConfig = getFirebaseConfig(env, rootConfig);

const initVueApp = async () =>  {

  await RemoteConfig(store, firebaseConfig);

  const config = mapConfig(env, rootConfig);

  const venueService = config.service_venue;
  const pinService = config.service_pin;
  const geoService = config.service_geo;

  sync(store, router);

  Vue.use(UserInfo(store, {
    venueService,
    geoService,
    pinService,
  }));
  Vue.use(FAQ);
  Vue.use(IdentityMigration(store, {
    venueService: config.service_venue,
    identityMigrationService: config.identityMigration,
  }));
  Vue.use(Footer);
  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h: any) => h(App),
  }).$mount('#app');

  (window as any)['funi-build'] = {
    app,
    env,
    stage: process.env.VUE_APP_STAGE,
    trigger: process.env.VUE_APP_TRIGGER,
    build: process.env.VUE_APP_BUILD,
  };


};

global.window.initVueApp = initVueApp;

const mountPoint: any = document.querySelector('#app');
if (mountPoint) {
  const isVueMounted  =  mountPoint.__vue__;
  if (!isVueMounted && global.window.region) {
    initVueApp();
  }
}
