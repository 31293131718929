import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
  },
  {
    path: '/migrate',
    name: 'migrate-view',
    component: () => import('../views/AnimeLabMigration/MigrateView.vue'),
  },
  {
    path: '/faq',
    name: 'faq-view',
    component: () => import('../views/FAQ/FAQView.vue'),
  },
  {
    path: '/activate',
    name: 'activate-view',
    component: () => import('../views/Activate/Activate.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    return { x: 0, y: 0, }
  },
});

export default router;
