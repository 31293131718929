import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = {
  state: {
    error: null,
    errors: [],
  } as any,
  actions: {
    dismissError(context: any) {
      context.commit("DISMISS_ERROR");
    },
    error(context: any, payload: Error) {
      console.error(payload);
      context.commit("ERROR", payload);
    },
  },
  mutations: {
    DISMISS_ERROR(state: any) {
      state.error = null;
    },
    ERROR(state: any, payload: Error) {
      state.error = payload;
      state.errors.push(payload);
    },
  }
};

export default new Vuex.Store(store);
